// React
import React from "react";
import PropTypes from "prop-types";

// Material ui
import {makeStyles} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {
  Close,
  DeleteForever
} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";

// Components
import LoadingIndicator from "../LoadingIndicator";
import ConfirmToolbar from "../ConfirmToolbar";

const useStyles = makeStyles((theme) => ({
  contentFullscreen: {
    paddingTop: 30,
    paddingBottom: 100,
    // [theme.breakpoints.up('md')]: {
    //   width: "5%",
    //   paddingRight: "5%",
    // },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "5%",
      paddingRight: "5%",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "15%",
      paddingRight: "15%",
    },
  },
  content: {
    // maxWidth: 800,
    margin: "auto",
  },
  contentPopup: {
    paddingBottom: 40,
    [theme.breakpoints.up("md")]: {
      width: 800,
    },
    [theme.breakpoints.down("sm")]: {},
  },
  dialogHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  dialogTitleContainer: {
    marginTop: "auto",
    marginBottom: "auto",
    display: "flex",
    width: "100%",
  },
  titleStartImage: {
    marginTop: "auto",
    marginBottom: "auto",
    marginRight: 10,
  },
  titleText: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  iconButton: {
    marginLeft: theme.spacing(1),
    marginTop: "auto",
    marginBottom: "auto",
    width: 48,
    height: 48,
  },
  deleteColor: {
    color: theme.palette.error.main,
  }
}));

export default function DialogContainer(props) {
  const classes = useStyles();
  const {
    open,
    onDelete,
    onClose,
    isLoading,
    fullScreen,
    children,
    dialogActions,
    startImg,
    title,
    editableTitle,
    titleFontVariant,
  } = props;
  const contentClass = fullScreen
    ? classes.contentFullscreen
    : classes.contentPopup;
  const [confirmRemoval, setConfirmRemoval] = React.useState(false);

  const handleConfirmRemoval = () => setConfirmRemoval(true);
  const handleCancelRemoval = () => setConfirmRemoval(false);


  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Dialog
      fullScreen={fullScreen || !isDesktop}
      open={open}
      onClose={onClose}
      scroll="paper"
      maxWidth="lg"
    >
      <DialogTitle id="scroll-dialog-title" disableTypography>
        <div className={classes.dialogHeaderContainer}>
          <div className={classes.dialogTitleContainer}>
            {startImg && (
              <img
                src={startImg}
                alt={editableTitle || title}
                height={30}
                width={30}
                className={classes.titleStartImage}
              />
            )}
            {editableTitle ? editableTitle : (
              <Typography
                component="h1"
                variant={titleFontVariant || "h5"}
                className={classes.titleText}
              >
                {title}
              </Typography>
            )}
          </div>
          <div>
            {confirmRemoval ? (
              <ConfirmToolbar
                onConfirm={onDelete}
                confirmButtonText="Delete"
                onCancel={handleCancelRemoval}
                variant="dense"
                disableGutters
              />
            ) : (
              <div style={{display: "flex"}}>
                {onDelete && (
                  <IconButton className={classes.iconButton} onClick={handleConfirmRemoval}>
                    <DeleteForever/>
                  </IconButton>
                )}
                {onClose && (
                  <IconButton className={classes.iconButton} onClick={onClose}>
                    <Close/>
                  </IconButton>
                )}
              </div>
            )}
          </div>

        </div>
      </DialogTitle>
      <DialogContent className={contentClass} dividers={Boolean(editableTitle || title)}>
        {isLoading ? (
          <LoadingIndicator
            message="Loading..."
            inheritHeight
          />
        ) : (
          <div id="dialog-content" className={classes.content}>
            {children}
          </div>
        )}
      </DialogContent>
      {dialogActions && (
        <DialogActions style={{padding: "15px 24px 15px 24px"}}>
          {dialogActions}
        </DialogActions>
      )}
    </Dialog>
  );
}

DialogContainer.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.any,
  editableTitle: PropTypes.any,
  isLoading: PropTypes.bool,
  fullScreen: PropTypes.bool,
  children: PropTypes.any,
};
