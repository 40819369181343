// React
import React from "react";
import PropTypes from "prop-types";

// Material UI
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme) => ({
  root: ({variant, maxHeight, gutterSpacingLeft}) => ({
    width: variant === "dense" ? "fit-content" : "100%",
    height: maxHeight || "inherit",
    marginTop: "auto",
    marginBottom: "auto",
    marginLeft: theme.spacing(gutterSpacingLeft || 0),
    display: "flex",
    justifyContent: "space-between",
  }),
  confirmButton: {
    marginLeft: 10,
    marginRight: 10,
  },
  alignVertically: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  denseBtnContainer: {
    paddingLeft: theme.spacing(1),
    display: "flex",
  }
}));

export default function ConfirmToolbar(props) {
  const {
    variant,
    confirmationText,
    onConfirm,
    confirmButtonText,
    onCancel,
    cancelButtonText,
    disableGutters,
    toolbarVariant,
  } = props;
  const classes = useStyles(props);

  if (variant === "dense") {
    return (
      <div className={classes.root}>
        <Typography className={classes.alignVertically}>
          Confirm:
        </Typography>
        <div className={classes.denseBtnContainer}>
          <Tooltip title={confirmButtonText || "Confirm"}>
            <IconButton
              className={classes.iconButtonCheck}
              color="primary"
              onClick={onConfirm}
            >
              <CheckIcon/>
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancel">
            <IconButton
              className={classes.iconButtonClear}
              onClick={onCancel}
            >
              <ClearIcon/>
            </IconButton>
          </Tooltip>
        </div>
      </div>
    )
  }

  return (
    <Toolbar
      className={classes.root}
      disableGutters={disableGutters}
      variant={toolbarVariant || "regular"}
    >
      {confirmationText && (
        <Typography>
          {confirmationText}
        </Typography>
      )}
      <div className={classes.buttonsContainer}>
        <Button
          id="delete-btn"
          variant="contained"
          color="primary"
          className={classes.confirmButton}
          onClick={onConfirm}
        >
          {confirmButtonText || "Yes, delete"}
        </Button>
        <Button id="delete-btn" variant="outlined" onClick={onCancel}>
          {cancelButtonText || "No, cancel"}
        </Button>
      </div>
    </Toolbar>
  );
}

ConfirmToolbar.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  confirmationText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
};
